<template>
  <div>
    <transition name="vload">
      <div v-show="isLoading" class="Loader">
        <div class="sk-cube-grid">
          <div class="sk-cube sk-cube1"></div>
          <div class="sk-cube sk-cube2"></div>
          <div class="sk-cube sk-cube3"></div>
          <div class="sk-cube sk-cube4"></div>
          <div class="sk-cube sk-cube5"></div>
          <div class="sk-cube sk-cube6"></div>
          <div class="sk-cube sk-cube7"></div>
          <div class="sk-cube sk-cube8"></div>
          <div class="sk-cube sk-cube9"></div>
        </div>
        <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
      </div>
    </transition>

    <div id="main-wrapper">
      <Header></Header>
      <div class="header-margin"></div>
      <!-- ============================ Page Title Start================================== -->
      <!--<div class="page-title page-title-large">
        <div class="container">

          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h4 class="ipt-title">SES企業・フリーランスエージェントリスト</h4>
              <span class="ipn-subtitle">首都圏を中心とした1,300社を超える国内最大規模のSES企業・フリーランスエージェントリストです。企業名とURLだけでなく、企業の基本情報や特徴まで掲載。自社にぴったりの協力会社・パートナー企業を見つけることができます。情報の更新・削除はお問い合わせから5営業日以内に反映致します。
								※ご利用の際の注意事項はページ下部をご確認ください。</span>
            </div>
          </div>
          <div>
            <div class="top-list">
              <ul>
                <li>アポ取得効率化！</li>
                <li>新規パートナー開拓！</li>
                <li>企業情報の更新で問い合わせ数増加！</li>
              </ul>
            </div>
          </div>
        </div>
      </div>-->
      <!-- ============================ Page Title End ================================== -->
      
      <BreadCrumb :pages="breadCrumbPages" :current-name="currentBreadCrumbName"></BreadCrumb>
      　
      <!-- ========================== Blog Detail Elements ============================= -->
      <section class="min-sec">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12">
              <h1 class="top-message">SESノウハウ一覧</h1>
              <div class="container">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12" v-for="item in this.currentPageItems" :key="item.id">
                    <div class="blog-wrap-grid">
                      <div class="blog-thumb">
                        <!--<router-link :to="`/article/${encodeURI(item.englishTitle)}`"><img :src="item.thumbnail" class="img-fluid" :alt="item.alt"></router-link>-->
                        <router-link :to="`/article/${encodeURI(item.englishTitle)}`">
                          <picture class="img-fluid w-100">
                            <source media="(min-width: 768px)" type="image/avif" :srcset="item.thumb_avif" width="333" height="187">
                            <source type="image/webp" :srcset="item.thumb_webp" width="333" height="187">
                            <img :src="item.thumbnail" class="img-fluid w-100" :alt="item.alt" width="333" height="187">
                          </picture>
                        </router-link>
                      </div>

                      <div class="blog-info">
                        <span class="post-date">公開日：{{item.createdAt}}</span>
                        <span class="post-date">更新日：{{item.updatedAt}}</span>
                      </div>

                      <div class="blog-body">
                        <h2 class="bl-title"><router-link :to="`/article/${encodeURI(item.englishTitle)}`">{{item.title}}</router-link></h2>
                      </div>

                      <div class="blog-cates">
                        <ul>
                          <li v-for="tag in item.tags" :key="item.id + tag.sys.id"><router-link :to="{ path: '/article', query: { tag: encodeURI(tag.sys.id) }}" class="blog-cates-list">{{allTags[tag.sys.id]}}</router-link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Pagenation -->
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <paginate ref="paginate" v-model="currentPage"
                            :page-count="pageCount"
                            :page-range="5"
                            :click-handler="onClickPage"
                            :prev-text="''"
                            :next-text="''"
                            :page-class="'page-item'"
                            :page-link-class="'page-link'"
                            :prev-link-class="'ti-arrow-left'"
                            :next-link-class="'ti-arrow-right'"
                            :container-class="'pagination'">
                  </paginate>
                </div>
              </div>
            </div>
            <side-area :tags="allTags"></side-area>
          </div>
        </div>
      </section>
      <!-- ========================== Blog Detail Elements ============================= -->
      <Footer></Footer>
    </div>
  </div>
</template>
<script>

  import Header from "@/views/components/Header";
  import Footer from "@/views/components/Footer";
  import Vue from 'vue'
  import moment from "moment"
  import Paginate from "vuejs-paginate";
  import {createContentfulClient} from "@/common/contentful";
  import SideArea from "@/views/components/SideArea";
  import BreadCrumb from "@/views/components/BreadCrumb";
  import MixIn from "@/common/mixin";

  const displayItemNum = 12;

  export default {
    name: 'Article',
    components: {BreadCrumb, SideArea, Footer, Header, Paginate},
    mixins: [MixIn],
    data: function() {
      const currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
      return {
        isRecommend: this.$route.query.recommend === true.toString(),
        tag: this.$route.query.tag ? decodeURI(this.$route.query.tag) : undefined,
        searchText: this.$route.query.search ? decodeURI(this.$route.query.search) : undefined,
        items: [],
        currentPageItems: [],
        isLoading: true,
        currentPage: currentPage,
        initPage: currentPage,
        allTags: {}
      }
    },
    created: function () {
      if (!Vue.prototype.$contentfulClient) {
        Vue.prototype.$contentfulClient = createContentfulClient();
      }
      this.loadEntries();
    },
    mounted: function() {
    },
    computed: {
      pageCount: {
        get() {
          return Math.ceil(this.items.length / displayItemNum);
        },
      },
      currentStartIndex: {
        get() {
          return (this.currentPage - 1) * displayItemNum;
        }
      },
      currentEndIndex: {
        get() {
          return this.currentStartIndex + displayItemNum;
        }
      },

      currentBreadCrumbName: {
        get() {
          if (this.searchText) {
            return this.searchText;
          } else if (this.tag && this.allTags) {
            return this.allTags[this.tag];
          }
          //return "記事一覧";
          return "SESノウハウ";
        }
      },

      breadCrumbPages: {
        get() {
          if (this.searchText || this.tag) {
            return [
              {
                path: "/article",
                //name: "記事"
                name: "SESノウハウ"
              }
            ]
          }
          return []
        }
      },
    },
    watch: {
      '$route.params': function () {
        this.resetParams();
      },
      '$route.query': function () {
        this.resetParams();
      }
    },
    methods: {
      async loadEntries () {
        const client = Vue.prototype.$contentfulClient;
        const query = {
          "content_type": "blog",
          order: '-sys.createdAt'
        };
        if (this.tag) {
          query["metadata.tags.sys.id[in]"] = this.tag;
        }
        if (this.searchText) {
          query["query"] = this.searchText;
        }
        if (this.isRecommend) {
          query["fields.pickup[in]"] = true;
        }
        const entries = await client.getEntries(query);

        // console.log(JSON.stringify(entries));

        this.items = entries.items.map(item => {
          return {
            id: item.sys.id,
            title: item.fields.title,
            englishTitle: item.fields.englishTitle,
            thumbnail: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url : "",
            //alt: item.fields.thumbnail ? item.fields.thumbnail.fields.description : "",
            tags: item.metadata.tags,
            createdAt: moment(item.sys.createdAt).format("YYYY/MM/DD"),
            updatedAt: moment(item.sys.updatedAt).format("YYYY/MM/DD"),

            //add.2024.06
            alt: item.fields.thumbnail ? item.fields.title  : "",
            thumb_avif: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=avif&q=50' : "" ,
            thumb_webp: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=webp&q=50' : "" ,
            thumb_jpg: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=jpg&fl=progressive&q=50' : "" ,
            thumb_w:item.fields.thumbnail ? item.fields.thumbnail.fields.file.details.image.width : "",
            thumb_h:item.fields.thumbnail ? item.fields.thumbnail.fields.file.details.image.height : "",
          }
        });

        const allTags = await client.getTags();
        this.allTags = Object.fromEntries(allTags.items.map(item => {
          return [item.sys.id, item.name]
        }));

        this.isLoading = false;

        this.updatePageList();
      },
      updatePageList() {
        this.currentPageItems = this.items.slice(this.currentStartIndex, this.currentEndIndex);
      },
      onClickPage(page) {
        const location = { path: '/article', query: { page: page.toString() } };
        if (this.searchText) {
          location.query.search = encodeURI(this.searchText);
        }
        if (this.tag) {
          location.query.tag = this.tag;
        }
        this.$router.push(location);
      },
      resetParams() {
        this.isLoading = true;
        this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
        this.searchText = this.$route.query.search ? decodeURI(this.$route.query.search) : undefined;
        this.tag = this.$route.query.tag ? decodeURI(this.$route.query.tag) : undefined;
        this.loadEntries();
        window.scrollTo(0, 0);
      }
    }
  }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vload-leave-active {
  transition: opacity 350ms;
}

.vload-leave-to{
  opacity: 0;
}
</style>
